import request from '../utils/request'
import baseUrl from './baseUrl'

export const groupBuyList = (data) => request({
  url: baseUrl + '/groupBuy/selectForBack',
  method: 'POST',
  data
})
export const addOrEditGroupBuy = (data) => request({
  url: baseUrl + '/groupBuy/add',
  method: 'POST',
  data
})
export const groupBuyDetail = (data) => request({
  url: baseUrl + '/groupBuy/selectForId',
  method: 'POST',
  data
})
export const groupBuyInvalid = (data) => request({
  url: baseUrl + '/groupBuy/modify',
  method: 'POST',
  data
})
export const delGroupBuy = (data) => request({
  url: baseUrl + '/groupBuy/deleteById',
  method: 'POST',
  data
})
export const selectIsUpgrade = (data) => request({
  url: baseUrl + '/groupBuy/selectIsUpgrade',
  method: 'POST',
  data
})
export const isActivity = (data) => request({
  url: baseUrl + '/activity/selectIsEnable',
  method: 'POST',
  data
})
export const upgradeList = (data) => request({
  url: baseUrl + '/groupBuyUpgrade/selectForBack',
  method: 'POST',
  data
})
export const addUpgrade = (data) => request({
  url: baseUrl + '/groupBuyUpgrade/add',
  method: 'POST',
  data
})
export const delUpgrade = (data) => request({
  url: baseUrl + '/groupBuyUpgrade/deleteById',
  method: 'POST',
  data
})
export const selectProdInfo = (data) => request({
  url: baseUrl + '/groupBuyUpgrade/selectProdInfo',
  method: 'POST',
  data
})
export const room = (data) => request({
  url: baseUrl + '/room/selectForGroupBuy',
  method: 'POST',
  data
})
export const ticket = (data) => request({
  url: baseUrl + '/ticket/selectForGroupBuy',
  method: 'POST',
  data
})
export const travel = (data) => request({
  url: baseUrl + '/travel/selectForGroupBuy',
  method: 'POST',
  data
})
export const changeSort = (data) => request({
  url: baseUrl + '/groupBuy/modifyDept',
  method: 'POST',
  data
}) 
export const wanlvGroupShow = (data) => request({
  url: baseUrl + '/groupBuy/modifyWlShow',
  method: 'POST',
  data
}) 
export const logList = (data) => request({
  url: baseUrl + '/groupLog/selectForBack',
  method: 'POST',
  data
}) 
export const logListDetails = (data) => request({
  url: baseUrl + '/groupLog/selectForId',
  method: 'POST',
  data
}) 
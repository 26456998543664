import request from '../utils/request'
import baseUrl from './baseUrl'
export const sceneryList = (data) => request({
  url: baseUrl + '/scenery/selectForBack',
  method: 'POST',
  data
})
export const addScenery = (data) => request({
  url: baseUrl + '/scenery/add',
  method: 'POST',
  data
})
export const editScenery = (data) => request({
  url: baseUrl + '/scenery/add',
  method: 'POST',
  data
})
export const sceneryDetails = (data) => request({
  url: baseUrl + '/scenery/selectForId',
  method: 'POST',
  data
})
// 修改排序 
export const changeSort = (data) => request({
  url: baseUrl + '/scenery/modifyDept',
  method: 'POST',
  data
})
// 上架 / 下级 / 删除 
export const changeStatus = (data) => request({
  url: baseUrl + '/scenery/modifyStatus',
  method: 'POST',
  data
})
// 查看所有景区 
export const allScenery = (data) => request({
  url: baseUrl + '/scenery/selectAll',
  method: 'POST',
  data
})
export const tj = (data) => request({
  url: baseUrl + '/ticket/modifyTj',
  method: 'POST',
  data
})
import request from '../utils/request'
import baseUrl from './baseUrl'

export const ticketList = (data) => request({
  url: baseUrl + '/ticket/selectForBack',
  method: 'POST',
  data
})
export const addTicket = (data) => request({
  url: baseUrl + '/ticket/add',
  method: 'POST',
  data
})
export const editTicket = (data) => request({
  url: baseUrl + '/ticket/add',
  method: 'POST',
  data
})
export const ticketDetails = (data) => request({
  url: baseUrl + '/ticket/selectForId',
  method: 'POST',
  data
})
//修改排序 
export const changeSort = (data) => request({
  url: baseUrl + '/ticket/modifyDept',
  method: 'POST',
  data
})
//上架/ 下架/ 删除 
export const changeStatus = (data) => request({
  url: baseUrl + '/ticket/modifyStatus',
  method: 'POST',
  data
})
// 通过id查询所有门票
export const selectAllBySceneryId = (data) => request({
  url: baseUrl + '/ticket/selectAllBySceneryId',
  method: 'POST',
  data
})
//一键复制
export const addByCody = (data) => request({
  url: baseUrl + '/ticket/addCopy',
  method: 'POST',
  data
})
//门票管理分类
export const ticketCateList = (data) => request({
  url: baseUrl + '/ticketCate/selectForBack',
  method: 'POST',
  data
})
export const addTicketCate = (data) => request({
  url: baseUrl + '/ticketCate/add',
  method: 'POST',
  data
})
export const delTicketCate = (data) => request({
  url: baseUrl + '/ticketCate/deleteById',
  method: 'POST',
  data
})
export const changeCateSort = (data) => request({
  url: baseUrl + '/ticketCate/modifyDept',
  method: 'POST',
  data
})
//所属分类
export const allCate = (data) => request({
  url: baseUrl + '/ticketCate/selectAll',
  method: 'POST',
  data
})
//生成二维码
export const createEr = (data) => request({
  url: baseUrl + '/ticket/selectErCode',
  method: 'POST',
  data
})
export const wanlvAllChange = (data) => request({
  url: baseUrl + '/ticket/modifyWlShow',
  method: 'POST',
  data
})
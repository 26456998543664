import request from '../utils/request'
import baseUrl from './baseUrl'

export const sceneryCate = (data) => request({
  url: baseUrl + '/sceneryCate/selectForBack',
  method: 'POST',
  data
})
export const addSceneryCate = (data) => request({
  url: baseUrl + '/sceneryCate/add',
  method: 'POST',
  data
})
export const editSceneryCate = (data) => request({
  url: baseUrl + '/sceneryCate/add',
  method: 'POST',
  data
})
export const sceneryCateDetails = (data) => request({
  url: baseUrl + '/sceneryCate/selectForId',
  method: 'POST',
  data
})
export const delSceneryCate = (data) => request({
  url: baseUrl + '/sceneryCate/deleteById',
  method: 'POST',
  data
})
export const changeSort = (data) => request({
  url: baseUrl + '/sceneryCate/modifyDept',
  method: 'POST',
  data
})
export const selectAllByFatherId = (data) => request({
  url: baseUrl + '/sceneryCate/selectAllByFatherId',
  method: 'POST',
  data
})
export const changeStatus = (data) => request({
  url: baseUrl + '/sceneryCate/upDownCate',
  method: 'POST',
  data
})
export const tj = (data) => request({
  url: baseUrl + '/scenery/modify',
  method: 'POST',
  data
})
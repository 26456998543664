<template>
  <div class="ticketCateList">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>门票分类</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="search">
      <span>分类名称:</span>
      <el-input
        v-model="list.cateName"
        placeholder="请输入门票名称"
      ></el-input>
      <el-button type="primary" @click="getticketCateList()">查找</el-button>
      <el-button
        type="primary"
        @click="$router.push('/addTicketCate/0/ ')"
        v-if="
          $store.state.powerList.indexOf('scenery:ticket:list:insert') !== -1
        "
        >添加</el-button
      >
    </div>
    <el-table
      :data="tableData"
      style="width: 100%"
      border
      @select="onSelectChange"
      @select-all="onSelectChange"
    >
      <el-table-column type="selection" prop="prop" width="width">
      </el-table-column>
      <el-table-column
        header-align="center"
        align="center"
        prop="dept"
        label="排序"
        width="180px"
      >
        <template slot-scope="{ row }">
          <el-input
            class="input-new-tag"
            v-if="row.inputVisible"
            v-model="row.dept"
            ref="saveTagInput"
            size="small"
            @input="onInput"
            @keyup.enter.native="handleInputConfirm(row)"
            @blur="handleInputConfirm(row)"
          >
          </el-input>
          <el-button
            v-else
            class="button-new-tag"
            size="small"
            @click="onchangeCateSort(row)"
            >{{ row.dept }}</el-button
          >
        </template>
      </el-table-column>
      <el-table-column
        header-align="center"
        align="center"
        prop="cateName"
        label="分类名称"
      >
      </el-table-column>
      <el-table-column
        header-align="center"
        align="center"
        prop="prop"
        label="操作"
        width="350"
      >
        <template slot-scope="{ row }">
          <el-button
            size="mini"
            type="primary"
            v-show="row.status == 2"
            @click="onChangeRowStatus(row, 1)"
            v-if="
              $store.state.powerList.indexOf('scenery:ticket:list:up') !== -1
            "
            >上架</el-button>
          <el-button
            size="mini"
            type="primary"
            v-show="row.status == 1"
            v-if="
              $store.state.powerList.indexOf('scenery:ticket:list:down') !== -1
            "
            @click="onChangeRowStatus(row, 2)"
            >下架</el-button
          >
          <el-button
            size="mini"
            type="primary"
            @click="$router.push(`/addTicketCate/${row.cateId}/${row.cateName}`)"
            >编辑</el-button
          >
           <el-button
            size="mini"
            type="primary"
            @click="onDelCate(row.cateId)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="btn">

      <el-button
        type="primary"
        @click="onChangeAllStatus(0)"
        v-if="
          $store.state.powerList.indexOf('scenery:ticket:list:delete') !== -1
        "
        >批量删除</el-button
      >
    </div>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="list.currentPage"
      :page-sizes="[5, 10, 15, 20]"
      :page-size="list.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="pagination.total"
    >
    </el-pagination>
  </div>
</template>
<script>
import { sceneryCate, selectAllByFatherId } from "../../api/sceneryCate";
import {
  ticketCateList,
  changeCateSort,
  changeStatus,
  addByCody,
  delTicketCate
} from "../../api/ticket";
import { isActivity } from "../../api/groupBuy";
import { isActivityKill } from "../../api/seckillInfo";
import { allScenery } from "../../api/sceneryList";
export default {
  name: "ticketCateList",
  data() {
    return {
      sceneryValue: "",
      sceneryOptions: [],
      sceneryOptions1: [],
      options: [
        {
          value: 0,
          label: "全部",
        },
        {
          value: 1,
          label: "上架",
        },
        {
          value: 2,
          label: "下架",
        },
      ],
      ids: "",
      tableData: [],
      pagination: {},
      list: {
        currentPage: 1,
        pageSize: 5,
        cateName: "",
        sceneryId: null,
        label: "",
        status: null,
      },
    };
  },
  watch: {
    "list.sceneryId": function () {
      this.$store.commit("setticketCateId", this.list.sceneryId);
    },
  },
  created() {
    this.list.sceneryId = this.$store.state.ticketCateId;

    this.getSceneryCateList();
    this.getticketCateList();
  },
  methods: {
    async onGOSecKill(ticketId) {
      const { data } = await isActivityKill({
        prodType: 2,
        prodId: ticketId,
      });
      if (data.code == 0) {
        this.$message.success(data.msg);
        this.$router.push(`/addSeckill/0/${ticketId}/${2}`);
      } else {
        this.$message.error(data.msg);
      }
    },
    async onGOGroupBuy(ticketId) {
      const { data } = await isActivity({
        prodType: 2,
        prodId: ticketId,
      });
      if (data.code == 0) {
        this.$message.success(data.msg);
        this.$router.push(`/addGroupBuy/0/${ticketId}/${2}`);
      } else {
        this.$message.error(data.msg);
      }
    },
    async addByCodyTicket(ticketId) {
      this.$confirm("确定一键复制这条收据?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const { data } = await addByCody({
            ticketId,
          });
          if (data.code == 0) {
            this.$message({
              type: "success",
              message: data.msg,
            });
            this.getticketCateList();
          } else {
            this.$message.error(data.msg);
          }
        })
        .catch(() => {});
    },
    onInput(value) {
      var reg = /^[0-9]*[1-9][0-9]*$/;
      var reg2 = /^-[0-9]*[1-9][0-9]*$/;
      if (!reg.test(value) && !reg2.test(value)) {
        return this.$message({
          type: "warning",
          message: "请输入整数!",
        });
      }
    },
    async getticketCateList() {
      const { data } = await ticketCateList(this.list);
      data.list.map((item) => {
        item.inputVisible = false;
      });
      this.tableData = data.list;
      this.pagination = data.pagination;
    },
    async getSceneryCateList() {
      const { data } = await allScenery();
      this.sceneryOptions = data.data;
    },
    onchangeCateSort(row) {
      row.inputVisible = true;
    },
    onSelectChange(selection) {
      var arr = [];
      selection.map((item) => {
        arr.push(item.ticketId);
      });
      this.ids = arr.join(",");
    },
    async handleInputConfirm(row) {
      const { data } = await changeCateSort({
        cateId: row.cateId,
        dept: Number(row.dept),
      });
      if (data.code !== 0) {
        this.$message({
          message: "设置失败",
          type: "error",
        });
      } else {
        this.$message({
          message: "设置成功",
          type: "success",
        });
      }
      row.inputVisible = false;
    },
    onChangeRowStatus(row, status) {
      this.$confirm("是否要改变选中门票的状态?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const { data } = await changeStatus({
            ids: row.ticketId + "",
            status,
          });
          if (data.code !== 0) {
            this.$message({
              type: "error",
              message: "设置失败!",
            });
          } else {
            this.getticketCateList();
            this.$message({
              type: "success",
              message: "设置成功!",
            });
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    onDelCate(id){
    this.$confirm("是否删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async res=>{
          console.log(res,'res')
          if(res=='confirm'){
              const {data} = await delTicketCate({cateId:id})
              this.$message.success('删除成功')
              this.getticketCateList()
          }
      })
    },
    onChangeAllStatus(status) {
      this.$confirm("是否要批量改变选中门票的状态?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          if (this.ids) {
            const { data } = await changeStatus({
              ids: this.ids,
              status,
            });
            if (data.code === 0) {
              this.getticketCateList();
              this.$message({
                type: "success",
                message: data.msg,
              });
            } else {
              this.$message({
                type: "error",
                message: data.msg,
              });
            }
          } else {
            this.$message({
              type: "error",
              message: "请选择要改变的内容!",
            });
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    onClear(i) {
      if (i === 1) {
        this.list.sceneryId = null;
      } else if (i === 2) {
        this.list.status = null;
      } else if (i === 5) {
        this.list.sceneryId = null;
      }
    },
    handleSizeChange(num) {
      this.list.pageSize = num;
      this.getticketCateList();
    },
    handleCurrentChange(num) {
      this.list.currentPage = num;
      this.getticketCateList();
    },
  },
};
</script>
<style lang="less" scoped>
.ticketCateList {
  .el-breadcrumb {
    height: 35px;
    border-bottom: 1px solid rgb(192, 191, 191);
  }
  .search {
    margin-top: 50px;
    .el-select {
      margin: 0 20px;
    }
    .select {
      margin: 0 10px;
    }
    .el-input {
      width: 200px;
      margin: 0 20px;
    }
  }
  .el-table {
    margin-top: 50px;
  }
  .btn {
    float: left;
    margin-top: 50px;
  }
  .el-pagination {
    float: right;
    margin-top: 50px;
  }
}
</style>